<template>
  <div>
    <div class="tot">
      <div>今日数据</div>
      <div>累计数据</div>
    </div>
    <div class="timebox">
      <el-date-picker value-format="yyyy-MM-dd" v-model="time" type="date" placeholder="选择日期"> </el-date-picker>
      <el-button style="margin-left: 30px" type="primary" @click="serch()">搜索</el-button>
    </div>
    <!-- 具体信息 -->
    <div class="tot-d">
      <el-row :gutter="20">
        <el-col :span="12" style="margin: 20px 0" >
          <div class="tot-del">
            <span >今日用户总览</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日活跃人数</div>
                    <div>{{ data.active }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日注册用户</div>
                    <div>{{ data.todayRegisterNum }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日成交人数</div>
                    <div>{{ data.todayTurnoverUserNum }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日实名认证人数</div>
                    <div>{{ data.todayAuthNum || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>总用户总览</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <!-- <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总活跃人数</div>
                    <div>{{ data.activeAll }}</div>
                  </div></el-col
                > -->
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总注册用户</div>
                    <div>{{ data.registerNum }}</div>
                  </div></el-col
                >
                <!-- <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总日成交人数</div>
                    <div>{{ data.turnoverUserNumAll }}</div>
                  </div></el-col
                > -->
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总实名认证人数</div>
                    <div>{{ data.authAllNum }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tot-d">
      <el-row :gutter="20">
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>今日交易统计</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日买入手续费</div>
                    <div>{{ $setNumbers(data.todayBuyServerChange) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日卖出手续费</div>
                    <div>{{ $setNumbers(data.todaySaleServerChange) || 0 }}</div>
                  </div></el-col
                >
                <!-- <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日流转手续费</div>
                    <div>{{ $setNumbers(data.todayTransferSelfServerChange) || 0 }}</div>
                  </div></el-col
                > -->
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日转出手续费</div>
                    <div>{{ $setNumbers(data.todayTransferOutServerChange) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日互转手续费</div>
                    <div>{{ $setNumbers(data.todayTransferSelfServerChange) || 0 }}</div>
                  </div></el-col
                >
                 <!-- <el-col :span="6"
                  ><div class="grid-content bg-purple" style="margin-top: 20px">
                    <div>今日钰荟数证手续费</div>
                    <div>{{ $setNumbers(data.todayGenServerchange) || 0 }}</div>
                  </div></el-col
                > -->
                
                
                <el-col :span="6"
                  ><div class="grid-content bg-purple" style="margin-top: 20px">
                    <div>今日成交金额</div>
                    <div>{{ $setNumbers(data.todayTurnoverAll) || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>累计交易统计</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总买入手续费</div>
                    <div>{{ $setNumbers(data.buyServerChange) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总卖出手续费</div>
                    <div>{{ $setNumbers(data.saleServerChange) || 0 }}</div>
                  </div></el-col
                >
                <!-- <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总流转手续费</div>
                    <div>{{ $setNumbers(data.transferServerChange) || 0 }}</div>
                  </div></el-col
                > -->
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总转出手续费</div>
                    <div>{{ $setNumbers(data.transferOutServerChange) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总互转手续费</div>
                    <div>{{ $setNumbers(data.transferServerChange) || 0 }}</div>
                  </div></el-col
                >
                 <!-- <el-col :span="6"
                  ><div class="grid-content bg-purple" style="margin-top: 20px">
                    <div>总钰荟数证手续费</div>
                    <div>{{ $setNumbers(data.allGenServerChange) || 0 }}</div>
                  </div></el-col
                > -->
                <el-col :span="6"
                  ><div class="grid-content bg-purple" style="margin-top: 20px">
                    <div>总成交金额</div>
                    <div>{{ $setNumbers(data.turnoverAll) || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="tot-d">
      <el-row :gutter="20">
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>今日交易统计</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日充值总额</div>
                    <div>{{ data.todayTop || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日提现总额</div>
                    <div>{{ data.todayWithdraw || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日充值手续费</div>
                    <div>{{ data.todayTopServerChange || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日提现手续费</div>
                    <div>{{ data.todayWithdrawServerChange || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple" style="margin-top: 20px">
                    <div>今日成功提现笔数</div>
                    <div>{{ data.todayWithdrawPassNum || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple" style="margin-top: 20px">
                    <div>今日失败提现笔数</div>
                    <div>{{ data.todayWithdrawFailNum || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple" style="margin-top: 20px">
                    <div>今日企业提现额</div>
                    <div>{{ data.todayFirmWithdrawAmount || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple" style="margin-top: 20px">
                    <div>今日企业提现手续费</div>
                    <div>{{ data.todayFirmWithdrawServerChange || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>累计交易统计</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总充值额</div>
                    <div>{{ $setNumbers(data.topAll) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总提现额</div>
                    <div>{{ $setNumbers(data.withdrawAll) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总充值手续费</div>
                    <div>{{ $setNumbers(data.topServerChangeAll) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总提现手续费</div>
                    <div>{{ $setNumbers(data.withdrawServerChangeAll) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple" style="margin-top: 20px">
                    <div>企业总提现额</div>
                    <div>{{ $setNumbers(data.firmWithdrawAmount) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple" style="margin-top: 20px">
                    <div>企业总提现手续费</div>
                    <div>{{ $setNumbers(data.firmWithdrawServerChange) || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="tot-d">
      <el-row :gutter="20">
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>今日提现量</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>提现手续费</div>
                    <div>{{ data.todayWithdrawServerChange || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>累计提现量</div>
                    <div>{{ data.todayWithdraw || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>累计提现手续费</div>
                    <div>{{ data.withdrawServerChangeAll || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div> -->
    <div class="tot-d">
      <el-row :gutter="20">
        <el-col :span="24" style="margin: 20px 0">
          <div class="tot-del">
            <span>通证</span>

            <div class="tot-dels">
              <div class="timebox">
                <el-date-picker value-format="yyyy-MM-dd" v-model="timetwo" type="date" placeholder="选择日期"> </el-date-picker>
                <!-- <el-button style="margin-left: 30px" type="primary" @click="serch()">搜索</el-button> -->
              </div>
              <el-row :gutter="20">
                <el-col :span="4" v-for="(t, i) in passData" :key="i">
                  <div
                    class="grid-content bg-purple"
                    style="margin-bottom: 20px; display: flex; justify-content: center; align-items: center"
                    @click="details(t)"
                  >
                    {{ t.tokenName }}
                    <!-- <div>{{ t.tokenName }}</div> -->
                    <!-- <div>交易量：{{ t.num || 0 }}</div>
                    <div>交易总额：{{ t.amount || 0 }}</div> -->
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="详情" :visible.sync="centerDialogVisible" width="30%" center>
      <span>
        <el-form label-position="top" :model="detailInfo">
          <el-form-item label="今日归集地转入量">
            <el-input disabled v-model="detailInfo.transferNum"></el-input>
          </el-form-item>
          <el-form-item label="今日归集地转出量">
            <el-input disabled v-model="detailInfo.transferOutNum"></el-input>
          </el-form-item>
          <el-form-item label="今日归集地转入手续费">
            <el-input disabled v-model="detailInfo.transferServerChange"></el-input>
          </el-form-item>
          <el-form-item label="今日交易量">
            <el-input disabled v-model="detailInfo.turnoverNum"></el-input>
          </el-form-item>
          <el-form-item label="今日交易总额">
            <el-input disabled v-model="detailInfo.turnoverAll"></el-input>
          </el-form-item>
          <el-form-item label="今日买入手续费">
            <el-input disabled v-model="detailInfo.buyServerChange"></el-input>
          </el-form-item>
          <el-form-item label="今日卖出手续费">
            <el-input disabled v-model="detailInfo.saleServerChange"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: '',
      time: '',
      timetwo: '',
      passData: '',
      userId:'',
      centerDialogVisible: false,
      detailInfo: ''
    }
  },
  created() {
    // 获取当前时间
    this.getnowTime()
    this.getShowPage()
    this.getPassList()
    
  },
  mounted() {
    // 获取当前账户
    this.getUserInfo()
    // this.$nextTick(() => {
			
		// })
    
  },
  methods: {
    getUserInfo() {
      const that = this
      const jjoi = JSON.parse(window.sessionStorage.getItem("admin"))
      if(jjoi.adminId == 30) {
        this.$router.push("/knockDown");
      }
      // const oldrouter = JSON.parse(sessionStorage.getItem("sidebar"))
      // if(oldrouter[0].actionId == 1) {
      //   console.log('存在首页')
      // }else {
      //   console.log('不存在首页')
      //   setTimeout(function(){
      //        const allrouter = JSON.parse(sessionStorage.getItem("newsidebar"))
      //        if(allrouter[0].children.length > 0) {
      //         const jpjj = "/" + allrouter[0].children[0].urlAddress
      //         that.$router.push(jpjj);
      //        }else {
      //         const bbhh = "/" + allrouter[0].urlAddress
      //         that.$router.push(bbhh);
      //        }
      //   }, 1000)
      // }
    },
    getnowTime() {
      var date = new Date()
      this.time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      this.timetwo = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    },
    // 搜索
    serch() {
      this.getShowPage()
    },
    getShowPage() {
      this.axios.get('/admin/page/showPage', { now: this.time }).then(res => {
        if (res.data.code == 200) {
          this.data = res.data.data
        } else {
          this.$message({
            message: '获取失败,请检查网络',
            type: 'error'
          })
        }
      })
    },
    getPassList() {
      this.axios.get('/admin/turnover/getCertificate', {}).then(res => {
        if (res.data.code == 200) {
          this.passData = res.data.data
        } else {
          this.$message({
            message: '获取失败,请检查网络',
            type: 'error'
          })
        }
      })
    },
    details(t) {
      console.log(t)
      this.axios.get('/admin/page/pageCertificate', { certificateId: t.certificateId, now: this.timetwo }).then(res => {
        this.centerDialogVisible = true
        this.detailInfo = res.data.data
        console.log(res, '777777777777')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tot {
  min-width: 1000px;
  padding: 30px;
  display: flex;
  justify-content: center;

  div {
    min-width: 100px;
    margin: 0 50px;
    padding: 5px 30px;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    text-align: center;
  }
}
.timebox {
  width: 100%;
  // height: 20px;
  // border: 1px solid red;
}

.tot-del {
  border: 1px solid #ebeef5;

  span {
    display: inline-block;
    padding: 18px 20px;
  }
}
.tot-dels {
  padding: 10px;
  .timebox {
    width: 100%;
    margin-bottom: 20px;
    // height: 20px;
    // border: 1px solid red;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  background-color: #a2b7ce;
  padding: 5px;
  border-radius: 4px;
  min-height: 36px;
  opacity: 0.5;
  div {
    text-align: center;
  }
}
</style>
